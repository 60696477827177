<template>
  <AppHeader />
    
  <div class="pl-5 pr-5 bg-blue-50 min-height" @scroll="handleScroll">
    <div class="posts-container">
      <PostWidget 
        v-for="post in posts" 
        :key="post.id" 
        :post="post" 
        @show-details="openPostDetails" 
      />
    </div>

    <PostDetailWidget 
      v-if="selectedPost" 
      :post="selectedPost" 
      @close="closePostDetails" 
    />

    <div v-if="loading" class="loading-indicator">Загрузка...</div>
  </div>
</template>

<script>
import PostWidget from '@/components/PostWidget.vue';
import PostDetailWidget from '@/components/PostDetailWidget.vue';
import AppHeader from '@/components/AppHeader.vue'; 
import Cookies from 'js-cookie';

export default {
  components: {
    PostWidget,
    PostDetailWidget,
    AppHeader, 
  },
  data() {
    return {
      posts: [],
      selectedPost: null,
      watched: 0,
      loading: false,
      allPostsLoaded: false, // Новый флаг для отслеживания состояния загрузки постов
    };
  },
  async created() {
    this.watched = 0; 
    Cookies.set('watched', this.watched, { 
      sameSite: 'None',
      secure: true,
      domain: '.aether-net.ru',
    });

    await this.fetchPosts();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async fetchPosts() {
      if (this.loading || this.allPostsLoaded) return; // Проверяем флаг

      this.loading = true;

      try {
        const response = await fetch(`https://api.aether-net.ru/api/get/trends/20`, {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const newPosts = await response.json();
          if (newPosts && Array.isArray(newPosts)) {
            // Фильтруем новые посты, чтобы исключить уже существующие
            const uniquePosts = newPosts.filter(newPost => 
              !this.posts.some(existingPost => existingPost.id === newPost.id)
            );

            // Если уникальные посты пустые, устанавливаем флаг
            if (uniquePosts.length === 0) {
              this.allPostsLoaded = true; // Устанавливаем флаг, если посты закончились
            } else {
              // Добавляем только уникальные посты
              this.posts = [...this.posts, ...uniquePosts];

              // Увеличиваем watched на 20 после загрузки новых постов
              this.watched += 20; // Увеличиваем на 20
              Cookies.set('watched', this.watched, { 
                sameSite: 'None',
                secure: true,
                domain: '.aether-net.ru',
              });
            }
          }
        } else {
          console.error('Ошибка при выполнении запроса');
          alert('Не удалось загрузить посты. Попробуйте позже.');
        }
      } catch (error) {
        console.error('Ошибка:', error);
        alert('Произошла ошибка при загрузке постов. Попробуйте еще раз.');
      } finally {
        this.loading = false;
      }
    },
    openPostDetails(post) {
      this.selectedPost = post;
    },
    closePostDetails() {
      this.selectedPost = null;
    },
    handleScroll() {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop + windowHeight >= documentHeight * 0.9) {
        this.fetchPosts();
      }
    }
  }
};
</script>

<style scoped>
.pl-5 {
  padding-left: 1.25rem; 
}

.pr-5 {
  padding-right: 1.25rem; 
}

.bg-blue-50 {
  background-color: #ebf8ff; 
}

.min-height {
  min-height: 100vh; 
}

.posts-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); 

  gap: 16px; /* Промежуток между плитками */
}

.loading-indicator {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #555;
}

@media (max-width: 768px) {
  .posts-container {
    grid-template-columns: 1fr; /* Одна колонка на мобильных устройствах */
  }
}
</style>
