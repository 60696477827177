<template>
  <div class="post-widget" @click="showDetails">
    <img :src="post.image_urls[0]" alt="Post Image" class="post-image" />
    <div class="post-info">
      <h2 class="post-description">{{ post.description }}</h2>
      <p class="post-date">{{ new Date(post.reg_date).toLocaleDateString() }}</p>
      <p class="post-stats">
        <strong>Лайки:</strong> {{ post.likes }} | 
        <strong>Просмотры:</strong> {{ post.views }}
      </p>
      <p class="post-author">
        <strong>Автор:</strong> <router-link :to="`/profile/${post.user_id}`">{{ post.username }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  methods: {
    showDetails() {
      this.$emit('show-details', this.post);
    }
  }
};
</script>

<style scoped>
.post-widget {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  cursor: pointer;
  display: flex;
  flex-direction: column; /* Устанавливаем вертикальное направление */
  height: 100%; /* Задаем высоту для равномерного распределения */
}

.post-widget:hover {
  transform: scale(1.02);
}

.post-image {
  width: 100%;
  height: 200px; /* Фиксированная высота для изображений */
  object-fit: cover; /* Обеспечивает правильное отображение изображения */
}

.post-description {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0 0 8px;
  overflow: hidden; /* Скрывает текст, выходящий за границы */
  text-overflow: ellipsis; /* Добавляет многоточие в конце, если текст обрезан */
  white-space: nowrap; /* Запрещает перенос строк */
}

.post-info {
  padding: 16px;
  flex-grow: 1; /* Позволяет этому блоку занимать оставшееся пространство */
  overflow: hidden; /* Скрывает текст, выходящий за границы */
}

.post-date,
.post-stats,
.post-author {
  overflow: hidden; /* Скрывает текст, выходящий за границы */
  text-overflow: ellipsis; /* Добавляет многоточие в конце, если текст обрезан */
  white-space: nowrap; /* Запрещает перенос строк */
}


.post-date {
  color: gray;
  font-size: 0.9em;
}

.post-stats {
  font-size: 0.9em;
  margin: 8px 0;
}

.post-author {
  font-size: 0.9em;
  color: blue;
}
</style>
